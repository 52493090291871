import React from 'react';
import {useSearchParams} from 'react-router-dom';

import swal from 'sweetalert';

import clientData from '../inc/clientData';
import config from '../inc/config';

class EmailActivationModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      validationHash: this.props.searchParams.get('validationHash') || ''
    };
  }

  componentDidMount() {
    console.log('EmailActivation: componentDidMount()');
    this.doJob();
  }

  componentWillUnmount() {
    console.log('EmailActivation: componentWillUnmount()');
  }

  doJob() {
    let infoMessage = '';

    if (!this.state.validationHash) {
      infoMessage = 'Doğrulama kodu boş bırakılamaz.';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      }).then(() => window.location.replace('/'));
    } else {
      const requestOptions = {
        method: 'PUT',
        headers: {'Authorization': 'Bearer ' + clientData.sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({
          validationHash: this.state.validationHash
        })
      };

      fetch(config.restApi + 'emailActivation', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch (responseData.status) {
          case 200:
            swal({
              dangerMode: false,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'Eposta adresiniz başarıyla doğrulandı.',
              buttons: 'Tamam'
            }).then(() => window.location.replace('/'));
          break;
          case 400:
            swal({dangerMode: true, icon: 'error', title: 'Hata', text: responseData.message, buttons: 'Yeniden Yükle'}).then(() => window.location.reload());
          break;
          case 403:
          case 500:
            swal({dangerMode: true, icon: 'error', title: 'Hata', text: responseData.message, buttons: 'Yeniden Dene'}).then(() => this.doJob());
          break;
          default:
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            }).then(() => window.location.replace('/'));
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Yeniden Dene'}).then(() => this.doJob()));
    }
  }

  render() {
    return (
      <div className="ibox-content sk-loading gray-bg w-100 h-100">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
    )
  }
}

export default function EmailActivation() {
  return (
    <EmailActivationModule searchParams={useSearchParams()[0]} />
  )
}