import React from 'react';
import {Link, useSearchParams} from 'react-router-dom';

import swal from 'sweetalert';

import clientData from '../inc/clientData';
import config from '../inc/config';
import {setCookie} from '../inc/cookies';

import Footer from '../components/Footer';
import Images from '../utils/Images';

class LoginModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      username: '',
      password: '',
      rememberMe: false
    };

    this.formOnSubmit = this.formOnSubmit.bind(this);
  }

  componentDidMount() {
    console.log('Login: componentDidMount()');
  }

  componentWillUnmount() {
    console.log('Login: componentWillUnmount()');
  }

  formOnSubmit(event) {
    event.preventDefault();

    let infoMessage = '';

    if (!this.state.username) {
      infoMessage = 'Kullanıcı Adı veya E-Posta adresi boş bırakılamaz.';
    } else if (!this.state.password) {
      infoMessage = 'Şifre boş bırakılamaz.';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.setState({isLoading: true});

      const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer ' + clientData.sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({
          username: this.state.username,
          password: this.state.password
        })
      };

      fetch(config.restApi + 'login', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch (responseData.status) {
          case 200:
            !this.state.rememberMe && setCookie('sessionHash', clientData.sessionHash);
            window.location.reload();
          break;
          case 400:
            swal({dangerMode: true, icon: 'error', title: 'Hata', text: responseData.message, buttons: 'Yeniden Yükle'}).then(() => window.location.reload());
          break;
          default:
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            }).then(() => this.setState({isLoading: false}));
          break;
        }
      }, () => this.setState({isLoading: false}, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'})));
    }
  }

  render() {
    return  this.state.isLoading ?
      <div className="ibox-content sk-loading gray-bg w-100 h-100">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="wrapper wrapper-content">
          <div className="text-center">
            <h2 className="font-normal text-dark">Kullanıcı Girişi</h2>
            <h4 className="font-normal">Kullanıcı girişi yapabilmek için aşağıdaki bilgileri doldurunuz.</h4>
          </div>
          <div className="middle-box p-5 text-center animated fadeInDown border bg-white mt-2">
            <div className="mb-5"><Link alt="logo" to={'//www.' + config.host}><img alt="Logo" src={Images.logo} /></Link></div>
            <form className="mt-2" onSubmit={this.formOnSubmit}>
              <div className="form-group">
                <h4 className="text-left">Kullanıcı adı veya E-posta</h4>
                <input className="form-control" onChange={event => this.setState({username: event.target.value})} required={true} type="text" value={this.state.username} />
              </div>
              <div className="form-group">
                <h4 className="text-left">Şifre</h4>
                <input className="form-control" onChange={event => this.setState({password: event.target.value})} required={true} type="password" value={this.state.password} />
              </div>
              <div className="d-flex justify-content-between mt-4 mb-4">
                <div className="form-check">
                  <label className="form-check-label"><input className="form-check-input" checked={this.state.rememberMe} onChange={event => this.setState({rememberMe: event.target.checked})} type="checkbox" /> Oturumu açık tut</label>
                </div>
                <div className="form-check">
                  <Link className="text-darkblue" to={"/forgetPassword" + (this.props.searchParams.get('ref') ? '?ref=' + this.props.searchParams.get('ref') : '')}>Şifremi Unuttum</Link>
                </div>
              </div>
              <button className="btn btn-login bg-orange text-white block full-width mb-2" type="submit">Giriş Yap</button>
              <Link className="btn full-width fp-button mb-2" to={"/magicLink" + (this.props.searchParams.get('ref') ? '?ref=' + this.props.searchParams.get('ref') : '')}>Sihirli Bağlantı ile Giriş Yap</Link>
              <div className="d-flex justify-content-center">
                <p className="text-dark mr-1">Hesabın yok mu?</p>
                <Link className="text-darkblue" to={"/register" + (this.props.searchParams.get('ref') ? '?ref=' + this.props.searchParams.get('ref') : '')}>Hesap Oluştur</Link>
              </div>
            </form>
          </div>
        </div>
        <Footer/>
      </>
  }
}

export default function Login() {
  return (
    <LoginModule searchParams={useSearchParams()[0]} />
  )
}