import React from 'react';

import Images from '../utils/Images';
import config from '../inc/config';

class FooterModule extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="row footer-background border border-gray m-0 border-right-0 border-left-0">
        <div className="container">
          <div className="row d-flex justify-content-between mb-3 pt-4">
            <ul className="d-flex d-sm-flex unstyled p-2">
              <div className="d-flex mr-1 mr-sm-4">
                <img alt="Tr Go" className="footer-images" src={Images.customerServiceIcon} />
                <div className="d-block">
                  <ul className="unstyled text-dark text-left pl-2 pl-sm-3">
                    <li className="font-bold">Müşteri Hizmetleri</li>
                    <li>+90 (212) 706 09 20</li>
                  </ul>
                </div>
              </div>
              <div className="d-flex">
                <img alt="Tr Go" className="footer-images" src={Images.helpCenterIcon} />
                <div className="d-block">
                  <ul className="unstyled pl-2 text-left text-dark">
                    <li className="font-bold">Yardım Merkezi</li>
                    <li><a className="text-dark" href={`https://yardim.${config.host}`} rel="noopener" target="_blank">yardim.satisgaranti.com</a></li>
                  </ul>
                </div>
              </div>
            </ul>
          </div>
          <div className="row d-flex mb-2 text-center px-2">
            <div className="text-dark text-left">satisgaranti.com'da yer alan kullanıcıların oluşturduğu tüm içerik, görüş ve bilgilerin doğruluğu, eksiksiz ve değişmez olduğu, yayınlanması ile ilgili yasal yükümlülükler içeriği oluşturan kullanıcıya aittir. Bu içeriğin, görüş ve bilgilerin yanlışlık, eksiklik veya yasalarla düzenlenmiş kurallara aykırılığından satisgaranti.com hiçbir şekilde sorumlu değildir. Sorularınız için ilan sahibi ile irtibata geçebilirsiniz.</div>
          </div>
          <hr />
          <div className="row d-flex justify-content-between mx-auto mx-sm-0">
            <div><strong className="mr-2">Copyright</strong>SG GARANTİ BİLİŞİM © 2019 - 2025</div>
          </div>
        </div>
      </div>
    )
  }
}

export default function Footer() {
  return (
    <FooterModule></FooterModule>
  )
} 